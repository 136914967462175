<template>
  <div class="table__container_main">
    <div class="table__container">
      <ul :style="columnsSizeStyle" class="table__header">
        <li v-for="headerItem in [...columns]" :key="headerItem.name">
          <sort-table
            v-if="headerItem.isSortable"
            :item="headerItem.name"
            @sort="sortHandler"
          >
            {{ $t(`table.${headerItem.name}`) }}
          </sort-table>
          <div v-else>{{ $t(`table.${headerItem.name}`) }}</div>
        </li>
      </ul>
      <ul class="table__content">
        <li
          v-for="item in content"
          :key="item.id"
          :style="columnsSizeStyle"
          class="table table__report items__container"
        >
          <div
            v-for="headerItem in [...columns]"
            :key="headerItem.name"
          >
            <template
              v-if="
                headerItem.name === 'name' && item[headerItem.name].length > 65
              "
            >
              <Tooltip
                :text="item[headerItem.name]"
                position="bottom"
                :is-dynamic="true"
              >
                <p class="extra__title">
                  {{ $t(`table.${headerItem.name}`) }}
                </p>
                {{ item[headerItem.name] }}
              </Tooltip>
            </template>
            <template v-else-if="headerItem.type === 'date'">
              <template v-if="!!item[headerItem.name]">
                <TableDate :date="item[headerItem.name]" />
              </template>
              <template v-else>
                {{ $t(`table.waiting_for_voting`) }}
              </template>
            </template>
            <template v-else-if="headerItem.name === 'vote'">
              <span
                class="item__vote"
                :class="`item__vote-${item[headerItem.name]}`"
              >
                {{ $t(`voting.${item[headerItem.name]}`) }}
              </span>
            </template>
            <template v-else-if="!headerItem.extra">
              <p class="extra__title">
                {{ $t(`table.${headerItem.name}`) }}
              </p>
              {{ item[headerItem.name] }}
            </template>
          </div>
        </li>
      </ul>

      <Pagination
        v-if="getPageQty > 1"
        @detectedPageValue="detectedPageValue"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tableColumnsSize from '@/models/table_columns';
import SortTable from '@/elements/SortTable.vue';
import TableRowMenuItem from '@/elements/TableRowMenuItem.vue';
import TableDate from '@/elements/TableDate.vue';
import Pagination from './Pagination.vue';

export default {
  components: {
    SortTable,
    TableRowMenuItem,
    Pagination,
    TableDate,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      //====== ????
      type: Array,
      default: () => [],
    },
    hasTooltip: {
      type: Boolean,
      default: true,
    },
    searchResults: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sortBy: '',
      selectedList: [],
      sort: {},
      isActive: false,
      selectAll: [],
      tableColumnsSize,
    };
  },

  computed: {
    ...mapGetters([
      'getTableModel',
      'getFilterTableModel',
      'getPageQty',
      'getCurrentIconStatus',
    ]),

    currentTableModel() {
      return this.$route.name === 'search-results'
        ? this.getFilterTableModel
        : this.getTableModel;
    },

    columns() {
      return this.header.length === 0
        ? this.currentTableModel[this.$route.name]?.columns
        : this.header;
    },

    columnsSizeStyle() {
      let columnsSize = 'grid-template-columns: ';
      this.columns?.forEach((element) => {
        columnsSize += this.tableColumnsSize[element.name] + ' ';
      });

      return columnsSize;
    },

    isTooltip() {
      //======== ???
      return this.tooltip === null
        ? this.currentTableModel[this.$route.name].tooltip
        : this.tooltip;
    },
  },

  methods: {
    iconStatus(item) {
      let iconStatus = 'info_questions';
      this.getCurrentIconStatus?.forEach((element) => {
        if (
          element.filling_status == item.filling_status &&
          element.review_status == item.review_status
        ) {
          iconStatus = element.icon;
        }
      });

      return iconStatus;
    },

    openCardItem(item) {
      let item_action =
        this.$route.name === 'search-results'
          ? this.currentTableModel.actions
          : this.currentTableModel[this.$route.name].actions[
              item.review_status
            ];

      let item_path_name =
        this.$route.name === 'search-results'
          ? this.currentTableModel[item.type_en]
          : this.currentTableModel[this.$route.name]?.path_name;

      this.$router.push({
        name: item_path_name,
        params: {
          id: item.uuid,
          activity: item_action,
          rooth_name: this.$route.name,
        },
      });
    },

    openMenu(value) {
      this.isActive = value;
    },
    detectedPageValue() {
      this.$emit('tableHandler');
    },
    sortHandler(payload) {
      this.$store.commit('SET_TABLE_SORT_DATA', { sort: payload });
      this.$emit('tableHandler');
    },
    // detectRoute(value) {
    //   this.routeToPush = value;
    // },
  },
};
</script>

<style lang="sass" scoped>
.table__header_checkbox,
.table__checkbox
  // opacity: 0
  display: none

// .table__container.table--cols_6
//   .table__header, .table__report
//     grid-template-columns: 17% 22% 28% 15% 15% 3%

// .table__container.table--cols_5
//   .table__header, .table__report
//     grid-template-columns: 30% 30% 17% 20% 3%

  .table__report > div:first-child
    max-width: 360px

// .table__container.table--cols_5.no_extra
//   .table__header, .table__report
//     grid-template-columns: 30% 10% 15% 15% 30%

.table__container.table--cols_5.no_extra
  .table__header > li:last-child, .table__report > div:last-child
    display: none

.table__container_main
  background-color: $white
  margin-bottom: 8rem

.disabled
  opacity: 0.5

.table__header, .table__report
  display: grid
  gap: 10px
  // grid-template-columns: 15% 19% 31% 14% 16% 5%
  align-items: center
  text-align: left
  @include s
    display: flex
    flex-wrap: wrap

.request-list__table .table__report
  margin-bottom: 50px

.table__content
  text-align: center
  gap: 10px
  font-size: 14px

.table--admin__news  .table__content .table__report div
  padding: 20px

.table--admin__news  .table__content .table__report div:first-child
  padding: 0px

.table--admin__news  .table__content .table__report div:last-child
  padding: 0px

.table--admin__news  .table__content .table__report div:nth-child(2) div
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical

.table--admin__news .table__header li
  padding: 20px

.table--admin__news .table__header li:first-child
  padding: 0px

.table--admin__news .table__header li:last-child
  padding: 0px

.table__header_checkbox
  margin-right: 24px
  min-width: 18px

.table--profile-ulmg-admin .table__wrapper
  display: block
.table--profile-ulmg-admin  .items__container div, .table--profile-smd-admin .items__container div div
  margin-bottom: 0px
  @include s
    margin-bottom: 12px

.table--profile-ulmg-admin .table__report div:first-child
  @include s
    height: 100px

.table--profile-smd-admin .table__report div:first-child
  @include xs
// height: 40px

.table__report.blocked > div:not(:first-child):not(:last-child)
  opacity: 0.5

.table__report label
  @include s
  margin-bottom: 12px

.extra-container, .header
  min-height: 47px

.items__container div
  display: flex
  align-items: center
  justify-content: start
  @include s
    width: 100%
    display: block
    color: $gray_active
    position: static
// margin-bottom: 20px
// height: 20px
// margin-top: 10px
.items__container div:last-child
  @include s
    margin-bottom: 10px

.item__center
  justify-content: center !important
  transform: translateX(-25px)

// .table__report div:first-child
//   @include xs
//     height: 23px

.table__report div:last-child
  @include s
// height: 30px

.table__report .container
  height: 84px

.items__container
  min-height: 82px
  @include s
    height: auto
    position: relative

.table__report label
  margin-right: 24px

.table--profile-ulmg-admin .table__header .checkbox__form
  border: 2px solid black
  margin-right: 24px

.extra__title
  display: none
  @include s
    display: block
    font-size: 12px
    line-height: 22px
    color: $black

.table__status-icon
  height: 2rem
  width: 2rem
  @include s
    height: 30px
    width: 24px
    position: absolute
    bottom: 40px
    right: 7px

.table__header
  font-size: 14px
  font-weight: 800
  @include s
    display: none

.table__content li
  border-bottom: 2px solid $gray_border
  margin-top: 10px
  @include s
    margin-top: 10px

.icon-success-check,
.icon-error-check
  margin-right: 12px

.vote_status
  width: 10px
  height: 10px
  margin-right: 10px
  border-radius: 50%
  background: #6EB862

.vote_status.accept
  background: #6EB862

.vote_status.edit
  background: #FFB33B

.vote_status.cancel
  background: #ED6A5E

.vote_status.downgrade
  background: #C4C4C4

.v-pagination
  display: none !important

.table__status-icon
  margin-right: 12px

.table__header
  border-bottom: 2px solid #EDEDED
  padding-bottom: 18px
  // height: 82px

.table__header li
  align-items: center
  display: flex

.table__content-item
  padding-right: 10px

.voting-upgrade
  color: $success

.voting-declined
  color: $error

.voting-revision
  color: $warning

.voting-downgrade
  color: #C4C4C4

.justify-to-start
  margin-right: auto

.table__report.draft
  color: $gray_notification

.item__vote
  // display: inline-block
  // padding: 10px 12px
  // border-radius: 50px
  // white-space: nowrap
  // font-size: 13px

  display: flex
  align-items: center
  &::before
    content: ''
    width: 13px
    height: 13px
    border-radius: 50%
    margin-right: 10px

.item__vote-in_waiting
  color: $gray
  &::before
    border: solid 1px $gray

.item__vote-upgrade
  color: #469661
  &::before
    background: #469661

.item__vote-downgrade
  color: $gray_active
  &::before
    background: $gray_active

.item__vote-declined
  color: #ED6A5E
  &::before
    background: #ED6A5E

.item__vote-revision
  color: #FFB33B
  &::before
    background: #FFB33B
</style>
